import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Works from "../sections/alan/Works";

import imgL from "../assets/image/png/alan-folded.png";
const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <Hero
          username="alan"
          fullname="Alan Sanchez"
          homegym="10th Planet San Mateo"
          pricing={<a>$125 for 1 student or <br/>2 students $75 each</a>}
          profile={'The original 10th Planet San Mateo black belt under Adam "BigRed" Sachnoff. EBI 19 winner, NoGi World Champion and Top-10 submission grappler on FloGrappling rankings at 170lbs. Fast-rising star in the nogi scene. Known for his smooth and highly technical back takes, kimuras and leglocks.'}
          imgL={imgL}
          calendly={"https://calendly.com/asanchezperez/1h"}

        />
        <Works />

      </PageWrapper>
    </>
  );
};
export default IndexPage;
